import React from "react";
import { MoonLoader } from "react-spinners";

const AppLoader = () => {
	return (
		<div className="app-loader">
			<MoonLoader color="#E4005B" size={24} />
		</div>
	);
};

export default AppLoader;
