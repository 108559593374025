import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import EmptyTable from "./EmptyTable";
import ToggleCell from "./customCells/ToggleCell";
import ActionCell from "./customCells/ActionCell";
import EditableCell from "./customCells/Editable";
import useNotification from "../../../hooks/useNotification";
import { deleteItem } from "../../../api/endpoints/dishesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
	selectMenu,
	updateMenu,
	setLoading,
} from "../../../features/menuSlice";

const rowKey = "id";

const { Column, HeaderCell } = Table;

// const ExpandCell = ({
// 	rowData,
// 	dataKey,
// 	expandedRowKeys,
// 	onChange,
// 	...props
// }: any) => (
// 	<Cell {...props} style={{ padding: 5 }}>
// 		<IconButton
// 			appearance="subtle"
// 			onClick={() => {
// 				onChange(rowData);
// 			}}
// 			icon={
// 				expandedRowKeys.some((key: number) => key === rowData[rowKey]) ? (
// 					<CollaspedOutlineIcon />
// 				) : (
// 					<ExpandOutlineIcon />
// 				)
// 			}
// 		/>
// 	</Cell>
// );

const renderRowExpanded = (rowData: any) => {
	return (
		<div>
			<div
				style={{
					width: 60,
					height: 60,
					float: "left",
					marginRight: 10,
					background: "#eee",
				}}
			>
				<img src={rowData.avatar} style={{ width: 60 }} />
			</div>
			<p>Email: {rowData.email}</p>
			<p>Phone: {rowData.phone}</p>
		</div>
	);
};

function MenuTable() {
	const [expandedRowKeys, setExpandedRowKeys] = React.useState<any[]>([]);
	const { dishes, loading } = useAppSelector(selectMenu);
	const dispatch = useAppDispatch();
	const { show } = useNotification();

	const handleItemToggle = (id: number, isChecked: boolean) => {
		const arr = dishes.map((item: any) => {
			if (item.id === id) {
				return { ...item, isVisible: isChecked };
			} else {
				return item;
			}
		});

		dispatch(updateMenu({ type: "dishes", data: arr }));
	};

	const handleExpanded = (rowData: any, event: any) => {
		let open = false;
		const nextExpandedRowKeys = [];

		expandedRowKeys.forEach((key) => {
			if (key === rowData[rowKey]) {
				open = true;
			} else {
				nextExpandedRowKeys.push(key);
			}
		});

		if (!open) {
			nextExpandedRowKeys.push(rowData[rowKey]);
		}

		setExpandedRowKeys(nextExpandedRowKeys);
	};

	const handleChange = (id: number, key: string, value: any) => {
		const nextData: any = Object.assign([], dishes);
		nextData.find((item: any) => item.id === id)[key] = value;
		dispatch(updateMenu({ type: "dishes", data: nextData }));
	};

	const handleEdit = (id: number) => {
		// const nextData = Object.assign([], data);
		// const activeItem: any = nextData.find((item: any) => item.id === id);
		// activeItem.status = activeItem.status ? null : "EDIT";
		// setData(nextData);
	};

	const handleDelete = async (id: number) => {
		dispatch(setLoading(true));

		const res = await deleteItem(id);

		if (res.success) {
			const arr = dishes.filter((item: any) => item.id !== id);
			dispatch(updateMenu({ type: "dishes", data: arr }));
			show("Success", res.message, "success");
		} else {
			// handle error
			show("Error", res.message, "error");
		}

		dispatch(setLoading(false));
	};

	return (
		<Table
			showHeader={true}
			style={{ marginTop: 40 }}
			renderEmpty={() => <EmptyTable isLoading={loading} />}
			shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
			data={dishes}
			// rowKey={rowKey}
			autoHeight={true}
			minHeight={200}
			// expandedRowKeys={expandedRowKeys}
			onRowClick={handleExpanded}
			// renderRowExpanded={renderRowExpanded}
			loading={loading}
		>
			<Column flexGrow={1} fixed>
				<HeaderCell style={{ fontSize: 14 }}>Name</HeaderCell>
				<EditableCell
					dataKey="name"
					dataType="string"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column align="right" flexGrow={1}>
				<HeaderCell style={{ fontSize: 14 }}>Description</HeaderCell>
				<EditableCell
					dataKey="description"
					dataType="string"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column align="right" flexGrow={1} fixed>
				<HeaderCell style={{ fontSize: 14 }}>Serv. amount</HeaderCell>
				<EditableCell
					dataKey="serving_amount"
					dataType="number"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column flexGrow={1} align="center">
				<HeaderCell style={{ fontSize: 14 }}>Availability</HeaderCell>
				<ToggleCell dataKey="display" toggle={handleItemToggle} />
			</Column>
			<Column flexGrow={1} align="center">
				<HeaderCell style={{ fontSize: 14 }}>Action</HeaderCell>
				<ActionCell
					dataKey="action"
					edit={handleEdit}
					deleteItem={handleDelete}
				/>
			</Column>
		</Table>
	);
}

export default MenuTable;
