import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { BiFoodMenu } from "react-icons/bi";
import { IoStorefrontOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { GoGear } from "react-icons/go";

import { AiOutlineHome } from "react-icons/ai";
import { LiaAdSolid } from "react-icons/lia";
import {
	CNavItem,
	CSidebar,
	CSidebarBrand,
	CSidebarHeader,
	CSidebarNav,
	CNavLink,
	CNavGroup,
} from "@coreui/react";
import { Flex } from "@mantine/core";

const panelStyles = {
	padding: "15px 20px",
	color: "#aaa",
};

const headerStyles = {
	padding: 20,
	fontSize: 16,
	background: "#34c3ff",
	color: " #fff",
};

const menuItems = [
	{ title: "Dashboard" },
	{ title: "Stores" },
	{ title: "Menu Manager" },
	{ title: "Insights" },
	{ title: "Account" },
];

// const NavItem = (props: any) => {
// 	const { title, eventKey, ...rest } = props;
// 	return (
// 		<Nav.Item eventKey={eventKey} as={Link} {...rest}>
// 			{title}
// 		</Nav.Item>
// 	);
// };

// const NavMenu = (props: any) => {
// 	const { title, eventKey, ...rest } = props;
// 	return (
// 		<Nav.Menu eventKey={eventKey} title={title} as={Link} {...rest}>
// 			{props.children}
// 		</Nav.Menu>
// 	);
// };

// const NavItem = (item: any, index: any, indent = false) => {
// 	const { component, name, ...rest } = item;
// 	const Component = component;
// 	return (
// 		<Component as="div" key={index}>
// 			{item.to || item.href ? (
// 				<CNavLink {...rest}>{item.name}</CNavLink>
// 			) : (
// 				item.name
// 			)}
// 		</Component>
// 	);
// };

const Sidebar = () => {
	// const [activeKey, setActiveKey] = useState<string>("1");

	return (
		<CSidebar className="border-end" colorScheme="dark">
			<CSidebarHeader className="border-bottom">
				<CSidebarBrand as="h4">Flavorfeed</CSidebarBrand>
			</CSidebarHeader>
			<CSidebarNav>
				<CNavItem as="div">
					<CNavLink to="/dashboard" as={NavLink} className="gap-2">
						<AiOutlineHome className="sidebar-icon" size={20} /> Dashboard
					</CNavLink>
				</CNavItem>

				<CNavItem as="div">
					<CNavLink to="/menu-manager" as={NavLink} className="gap-2">
						<BiFoodMenu className="sidebar-icon" size={20} /> Menu Manager
					</CNavLink>
				</CNavItem>

				<CNavItem as="div">
					<CNavLink to="/store" as={NavLink} className="gap-2">
						<IoStorefrontOutline className="sidebar-icon" size={20} />{" "}
						Store
					</CNavLink>
				</CNavItem>

				<CNavItem as="div">
					<CNavLink to="/ads" as={NavLink} className="gap-2">
						<LiaAdSolid className="sidebar-icon" size={20} /> Ads
					</CNavLink>
				</CNavItem>

				<CNavItem as="div">
					<CNavLink to="/users" as={NavLink} className="gap-2">
						<FiUsers className="sidebar-icon" size={20} /> Users
					</CNavLink>
				</CNavItem>

				<CNavGroup
					toggler={
						<Flex gap="5" align="center">
							<GoGear className="sidebar-icon" size={20} /> Settings
						</Flex>
					}
				>
					<CNavItem as="div">
						<CNavLink to="/account" as={Link}>
							<span className="nav-icon">
								<span className="nav-icon-bullet"></span>
							</span>{" "}
							Account
						</CNavLink>
					</CNavItem>

					<CNavItem as="div">
						<CNavLink to="/billing" as={Link}>
							<span className="nav-icon">
								<span className="nav-icon-bullet"></span>
							</span>{" "}
							Billing
						</CNavLink>
					</CNavItem>

					{/* <Link to="/notifications">
						<CNavItem href="#">
							<span className="nav-icon">
								<span className="nav-icon-bullet"></span>
							</span>{" "}
							Notifications
						</CNavItem>
					</Link> */}

					<CNavItem as="div">
						<CNavLink to="/settings/account-security" as={Link}>
							<span className="nav-icon">
								<span className="nav-icon-bullet"></span>
							</span>{" "}
							Security
						</CNavLink>
					</CNavItem>

					<CNavItem as="div">
						<CNavLink to="/settings/notifications" as={Link}>
							<span className="nav-icon">
								<span className="nav-icon-bullet"></span>
							</span>{" "}
							Notifications
						</CNavLink>
					</CNavItem>
				</CNavGroup>

				{/* <CNavItem as="div">
					<CNavLink to="/settings" as={NavLink} className="gap-2">
						<GoGear className="sidebar-icon" size={20} /> Settings
					</CNavLink>
				</CNavItem> */}
			</CSidebarNav>
		</CSidebar>
	);

	// return (
	// 	// <CustomProvider theme="dark">
	// 	<div className="sidebar">
	// 		<Sidenav className="sidenav" defaultOpenKeys={["3", "4"]}>
	// 			<Sidenav.Header className="sidebar-header">
	// 				<Typography variant="h5">
	// 					<Link to="dashboard">Flavorfeed</Link>
	// 				</Typography>
	// 			</Sidenav.Header>
	// 			<Sidenav.Body>
	// 				<Nav activeKey={activeKey} onSelect={setActiveKey}>
	// 					<NavItem
	// 						title="Dashboard"
	// 						eventKey="1"
	// 						to="/dashboard"
	// 						icon={<DashboardIcon />}
	// 					/>
	// 					{/* <Nav.Item eventKey="1" icon={<DashboardIcon />}>
	// 					Dashboard
	// 				</Nav.Item> */}
	// 					{/* <Nav.Item eventKey="2" icon={<GroupIcon />}>
	// 					Menu
	// 				</Nav.Item> */}
	// 					<NavItem
	// 						className="sidebar-menu"
	// 						title="Stores"
	// 						eventKey="2"
	// 						to="/stores"
	// 						icon={<IoStorefront className="sidebar-icon" />}
	// 					/>

	// 					<NavItem
	// 						className="sidebar-menu"
	// 						title="Menu"
	// 						eventKey="3"
	// 						to="/menu-manager"
	// 						icon={<BiSolidFoodMenu className="sidebar-icon" />}
	// 					/>
	// 					{/* <Nav.Menu
	// 					className="sidebar-menu"
	// 					eventKey="2"
	// 					title="Stores"
	// 					icon={<IoStorefront className="sidebar-icon" />}
	// 				>
	// 					<Nav.Item eventKey="2-1">Customer Reviews</Nav.Item>
	// 					<Nav.Item eventKey="2-2">Devices</Nav.Item>
	// 					<Nav.Item eventKey="2-3">Loyalty</Nav.Item>
	// 					<Nav.Item eventKey="2-4">Visit Depth</Nav.Item>
	// 				</Nav.Menu> */}
	// 					{/* <Dropdown.Separator /> */}

	// 					{/* <Nav.Menu
	// 						title="Menu"
	// 						eventKey="3"
	// 						className="sidebar-menu"
	// 						icon={<BiSolidFoodMenu className="sidebar-icon" />}
	// 					>
	// 						<Nav.Item eventKey="3-1">List</Nav.Item>
	// 						<Nav.Item eventKey="3-2">Customer Reviews</Nav.Item>
	// 						<Nav.Item eventKey="3-3">Loyalty</Nav.Item>
	// 						<Nav.Item eventKey="3-4">Visit Depth</Nav.Item>
	// 					</Nav.Menu> */}
	// 					{/* <Nav.Menu
	// 					className="sidebar-menu"
	// 					eventKey="3"
	// 					title="Insights"
	// 					icon={<FaChartBar className="sidebar-icon" />}
	// 				>
	// 					<Nav.Item eventKey="3-1">Customer Reviews</Nav.Item>
	// 					<Nav.Item eventKey="3-2">Devices</Nav.Item>
	// 					<Nav.Item eventKey="3-3">Loyalty</Nav.Item>
	// 					<Nav.Item eventKey="3-4">Visit Depth</Nav.Item>
	// 				</Nav.Menu> */}
	// 					<Nav.Item panel style={panelStyles}>
	// 						Insights
	// 					</Nav.Item>

	// 					<Dropdown.Separator />
	// 					<Nav.Item panel style={panelStyles}>
	// 						Account
	// 					</Nav.Item>
	// 					<Nav.Item eventKey="10">Profile</Nav.Item>
	// 					<Nav.Item>Billing</Nav.Item>
	// 					{/* <Nav.Menu eventKey="4" title="Account" icon={<GearCircleIcon />}>
	// 					<Nav.Item eventKey="4-1">Applications</Nav.Item>
	// 					<Nav.Item eventKey="4-2">Channels</Nav.Item>
	// 					<Nav.Item eventKey="4-3">Versions</Nav.Item>
	// 					<Nav.Menu eventKey="4-5" title="Custom Action">
	// 						<Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
	// 						<Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
	// 					</Nav.Menu>
	// 				</Nav.Menu> */}
	// 				</Nav>
	// 			</Sidenav.Body>
	// 		</Sidenav>
	// 	</div>
	// 	// </CustomProvider>
	// );
};

export default Sidebar;
