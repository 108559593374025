import React from "react";

type StoreInfoProps = {
	data: {
		id: number;
		name: string;
		phone: string;
		address_1: string;
		address_2: string;
		city: string;
		state: string;
		zip: number;
	};
};

function StoreInformation({ data }: StoreInfoProps) {
	return (
		<div className="store-info">
			<div className="data-group">
				<h5>Store Name</h5>
				<span>{data?.name}</span>
			</div>
			<div className="data-group">
				<h5>Address</h5>
				<p>{data?.address_1}</p>
			</div>
			<div className="data-group">
				<h5>Address 2</h5>
				<p>{data?.address_2}</p>
			</div>
			<div className="data-group">
				<h5>City</h5>
				<p>{data?.city}</p>
			</div>
			<div className="data-group">
				<h5>State</h5>
				<p>{data?.state}</p>
			</div>
			<div className="data-group">
				<h5>Zip </h5>
				<p>{data?.zip}</p>
			</div>
		</div>
	);
}

export default StoreInformation;
