import { createClient } from "@supabase/supabase-js";

const supabaseUrl: any = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey: any = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Upload file using standard upload
export async function uploadFile(bucket: string, filePath: string, file: any) {
	const { data, error } = await supabase.storage
		.from(bucket)
		.upload(filePath, file);

	if (error) {
		// Handle error
		console.log("Error uploading ", error);
	} else {
		// Handle success
		console.log("successfully uploaded", data);
		return data;
	}
}
