import { createSlice } from "@reduxjs/toolkit";

interface Dish {
	id: number;
	name: string;
	description: string;
	serving_amount: number;
	isVisible: boolean;
	category: string;
}

interface Category {
	id: number;
	name: string;
}

export interface Menu {
	categories: Category[] | [];
	dishes: Dish[];
	isFetching: boolean;
	loading: boolean;
}

const initialState: Menu = {
	categories: [],
	dishes: [],
	isFetching: false,
	loading: true,
};

const menuSlice = createSlice({
	name: "menu",
	initialState,
	reducers: {
		updateMenu: (state, action) => {
			const { payload } = action;
			console.log("payload", payload);

			if (payload.type === "category") {
				state.categories = payload.data;
			}

			if (payload.type === "dishes") {
				console.log("dishes payload", payload.data);
				state.dishes = payload.data;
			}

			// state.loading = false;
		},

		setIsFetching: (state) => {
			console.log("is fetching", true);
			state.isFetching = true;
			state.loading = true;
		},

		setLoading: (state, action) => {
			console.log("is loading", action.payload);
			state.loading = action.payload;
		},

		// updateCategories: (state, action) => {
		// 	const categories = action.payload;
		// 	console.log("categories payload", categories);
		// 	state.categories = categories;
		// },

		// updateDishes: (state, action) => {
		// 	const dishes = action.payload;
		// 	console.log("categories dishes", dishes);
		// 	state.categories = dishes;
		// },
	},
});

export const { updateMenu, setIsFetching, setLoading } = menuSlice.actions;

export const selectMenu = (state: any) => state.menu;

export default menuSlice.reducer;
