import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/layouts/ContentLayout";
import {
	Group,
	Button,
	FileButton,
	Stack,
	TextInput,
	Box,
} from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import { Avatar } from "@mui/material";
import AppButton from "../../components/buttons/AppButton";
import { updateUser, User } from "../../api/endpoints/usersApi";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";
import { getUserProfile } from "../../api/endpoints/usersApi";
import AppLoader from "../../components/AppLoader";
import useNotification from "../../hooks/useNotification";
import { uploadFile } from "../../api/endpoints/uploadsApi";

function Account() {
	const { show } = useNotification();
	const { user } = useAppSelector(selectAuth);
	const [isLoading, setIsLoading] = useState(true);
	const [isEditing, setIsEditing] = useState(false);
	const [file, setFile] = useState<any>(null);
	const [avatarPreview, setAvatarPreview] = useState<any>(null);
	console.log("file", file);
	console.log("user", user);

	const [profile, setProfile] = useState<User>({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setProfile({ ...profile, [name]: value });
	};

	const handleAvatarUpload = async () => {
		const filepath = `${user.id}/${file.name}`;
		const response: any = await uploadFile("avatar", filepath, file);
		console.log("avatar upload response", response);
		const { id, fullPath, path } = response;

		const metadata = {
			bucket_id: "avatars",
			user_id: user.id,
			filename: file.name,
			id: id,
			fullPath,
			path,
			s3_location: filepath,
			doctype: file.type,
			filesize: file.size,
		};
	};

	const saveChanges = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);

		const response = await updateUser(user.id, profile);

		if (response.success) {
			// handle success
			setIsLoading(false);
			show("", "Personal information was updated successfully.", "success");
		} else {
			// handle error
			setIsLoading(false);
			show("", response.data.message, "error");
		}
	};

	const changeAvatar = async () => {
		// const response = await uploadAvatar(user.id, file);
		// if (response.success) {
		// 	// handle success
		// } else {
		// 	// handle error
		// }
	};

	const removeAvatar = async () => {
		// const response = await deleteAvatar(user.id);
		// if (response.success) {
		// 	// handle success
		// } else {
		// 	// handle error
		// }
	};

	const cancelChanges = () => {
		setIsEditing(false);
		setFile(null);
		setAvatarPreview(null);
	};

	const handleAccountDelete = async () => {
		// const response = await deleteAccount(user.id);
		// if (response.success) {
		// 	// handle success
		// } else {
		// 	// handle error
		// }
	};

	useEffect(() => {
		const fetchUserProfile = async () => {
			setIsLoading(true);

			const response = await getUserProfile(user.id);

			if (response.success) {
				const { first_name, last_name, email, phone } = response.data;

				const obj = {
					first_name: first_name ?? "",
					last_name: last_name ?? "",
					email: email ?? "",
					phone: phone ?? "",
				};

				setProfile(obj);
			}

			setIsLoading(false);
		};

		fetchUserProfile();
	}, []);

	useEffect(() => {
		if (file) {
			setIsEditing(true);
			const objectUrl = URL.createObjectURL(file);
			setAvatarPreview(objectUrl);

			return () => URL.revokeObjectURL(objectUrl);
		}
	}, [file]);

	if (isLoading) {
		return <AppLoader />;
	}

	return (
		<ContentLayout heading="Account">
			<Stack gap={30}>
				<DataPanel title="Personal Information">
					<Box maw={"80%"}>
						<Stack>
							<Group grow>
								<TextInput
									label="First Name"
									type="text"
									name="first_name"
									value={profile.first_name}
									onChange={handleChange}
									disabled={!isEditing}
								/>
								<TextInput
									label="Last Name"
									type="text"
									name="last_name"
									value={profile.last_name}
									onChange={handleChange}
									disabled={!isEditing}
								/>
							</Group>

							<div className="avatar-group">
								<span className="text-sm font-semibold">Photo</span>

								<div className="flex gap-x-4 mt-2 item-center">
									<Avatar src={avatarPreview} />
									<Group>
										<FileButton
											onChange={setFile}
											accept="image/png,image/jpeg"
										>
											{(props) => (
												<Button variant="default" {...props}>
													Change
												</Button>
											)}
										</FileButton>
										<Button
											variant="transparent"
											style={{ padding: 4 }}
											color="red"
										>
											Remove
										</Button>
									</Group>
								</div>
							</div>

							<Group grow>
								<TextInput
									label="Email Address"
									type="text"
									name="email"
									value={profile.email}
									onChange={handleChange}
									disabled={!isEditing}
								/>
								<TextInput
									label="Contact Number"
									type="text"
									name="phone"
									value={profile.phone}
									onChange={handleChange}
									disabled={!isEditing}
								/>
							</Group>
							{/* <TextInput label="Role" /> */}
						</Stack>
					</Box>
					<Group justify="flex-end" className="mt-8">
						<Button variant="default" onClick={cancelChanges}>
							Cancel
						</Button>

						<AppButton
							label="Save Changes"
							onClick={saveChanges}
							loading={isLoading}
							disabled={!isEditing}
						/>
					</Group>
				</DataPanel>

				<DataPanel style={{ color: "#d1242f" }} title="Delete Account">
					<Button
						bg="#eaeef2b3"
						style={{ borderColor: "#d0d7de", color: "#d1242f80" }}
						onClick={handleAccountDelete}
					>
						Delete My Account
					</Button>
				</DataPanel>
			</Stack>
		</ContentLayout>
	);
}

export default Account;
