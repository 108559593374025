import React, { useEffect, useState } from "react";
import { Panel } from "rsuite";
import DishCard from "../../components/DishCard";
import Categories from "./components/Categories";
import MenuTable from "./components/MenuTable";

import DISH1 from "../../assets/dish_1.jpeg";
import DISH2 from "../../assets/dish2.jpeg";
import ActionButtons from "./components/ActionButtons";
import { createNewCategory, getDishes } from "../../api/endpoints/dishesApi";
import { stringToLowerCase } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { updateMenu, selectMenu, setLoading } from "../../features/menuSlice";
import useNotification from "../../hooks/useNotification";
import { selectAuth } from "../../features/authSlice";

const MOCK_DATA = [
	{
		id: 1,
		name: "Oysters On The Shell",
		description: "Half Dozen 24, Dozen 42",
		serving_amount: 2,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 2,
		name: "Chicken Tortilla Soup",
		description: "pico de gallo, avocado, crema, queso blanco",
		serving_amount: 0,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 3,
		name: "Truffled Brie Toast",
		description: "cranberry preserve, black truffle, honeycomb, chive",
		serving_amount: 0,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 4,
		name: "Jumbo Lump Crab Cake",
		description: "dhsadhsakfj djpaso jd",
		serving_amount: 0,
		isVisible: true,
		category: "Meats",
	},
	{
		id: 5,
		name: "Short Rib Potstickers",
		description: "djsadas jgldkgjgk",
		serving_amount: 0,
		isVisible: true,
		category: "Salads",
	},
];

export interface Category {
	value: number;
	label: string;
}

function MenuManager() {
	const [tabActive, setTabActive] = useState(0);
	const [data, setData] = useState<any[]>([]);
	const [byCategory, setByCategory] = useState<any[]>([]);
	const [isFiltered, setFiltered] = useState(false);
	const { dishes } = useAppSelector(selectMenu);
	const { show } = useNotification();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(selectAuth);
	console.log("user", user);
	console.log("dishes", dishes);

	const [categories, setCategories] = useState<Category[] | []>([]);

	const handleTabClick = (tab: number) => {
		if (tab === 0) {
			setByCategory([]);
			setFiltered(false);
		}
		const arr = data.filter(
			(item: any) =>
				stringToLowerCase(item.label) ===
				stringToLowerCase(categories[tab].label)
		);
		setTabActive(tab);
		setByCategory(arr);
		setFiltered(true);
	};

	const createCategory = async (val: string) => {
		const res = await createNewCategory({
			store_id: user.store_id,
			categoryName: val,
		});

		if (res.success) {
			const arr = [
				...categories,
				{ value: categories.length + 1, label: val },
			];
			setCategories(arr);
		} else {
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch(setLoading(true));

			const res = await getDishes(user.store_id);
			console.log("res", res);

			if (res.success) {
				if (!res.data.categories) {
					setCategories([{ value: 0, label: "All" }]);
				} else {
					const list = [
						{ value: 0, label: "All" },
						...res.data.categories,
					];

					setCategories(list);
					setData(list);
				}

				dispatch(updateMenu({ type: "dishes", data: res.data.dishes }));
				dispatch(setLoading(false));
			} else {
				// handle error
				show("Error", res.message, "error");
			}

			dispatch(setLoading(false));
		};

		// if (!dishes && !dishes.length) {
		fetchData();
		// }
	}, []);

	return (
		<Panel
			id="menu-manager"
			header={<ActionButtons categories={categories} />}
		>
			<div className="content">
				<Categories
					data={categories}
					active={tabActive}
					onClick={handleTabClick}
					onSave={createCategory}
				/>
				<MenuTable />
			</div>
		</Panel>
	);
}

export default MenuManager;
