import axios from "axios";
import { refreshToken } from "./endpoints/authApi";
import {
	clearStorage,
	getAccessToken,
	getRefreshToken,
	saveAccessToken,
} from "../utils/tokenUtils";

export const authInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

export const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

apiInstance.interceptors.request.use(
	async function (config) {
		const token = getAccessToken();

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		if (config.data instanceof FormData) {
			apiInstance.defaults.headers.common[
				"Authorization"
			] = `Bearer ${token}`;
			apiInstance.defaults.headers.common["Content-Type"] =
				"multipart/form-data";
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
apiInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	async function (error) {
		const { response, config } = error;
		const originalRequest = config;
		console.error(response, originalRequest);

		// refresh token
		if (response.status === 403 && !originalRequest._retry) {
			originalRequest._retry = true;
			const token = getRefreshToken();

			if (token) {
				try {
					const { data } = await refreshToken(token);
					saveAccessToken(data.token);

					apiInstance.defaults.headers.common["Authorization"] =
						data.token;

					// return apiInstance(originalRequest);
					return apiInstance(error.config);
				} catch (err) {
					// handle error
					console.error("Token refresh failed:", err);
					clearStorage();
					window.location.href = "/login";

					return Promise.reject(err);
				}
			}
		}

		return Promise.reject(error);
	}
);
