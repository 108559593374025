import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { MdDelete } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";

const containerStyle = {
	border: "2px dashed #e9e9e9",
	borderRadius: 4,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

const thumbsContainer: any = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16,
};

const thumb: any = {
	position: "relative",
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 60,
	height: 60,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
};

const img = {
	display: "block",
	width: "auto",
	height: "100%",
};

type Props = {
	files: any[];
	showPreview: boolean;
	setFiles: (files: any) => void;
	children: React.ReactNode;
};

export default function FileDropzone({
	files,
	showPreview,
	setFiles,
	children,
}: Props) {
	const onDrop = useCallback(
		(acceptedFiles: any) => {
			const newFiles = acceptedFiles.map((file: any, index: number) =>
				Object.assign(file, {
					id: index + 1,
					preview: URL.createObjectURL(file),
				})
			);

			setFiles([...files, ...newFiles]);
		},
		[files, setFiles]
	);

	const thumbs = files.map((file: any, index: number) => (
		<div style={thumb} key={file.path}>
			<div style={thumbInner}>
				<img
					alt="file uploaded"
					src={file.preview}
					style={img}
					// Revoke data uri after image is loaded
					onLoad={() => {
						URL.revokeObjectURL(file.preview);
					}}
				/>
			</div>
		</div>
	));

	return (
		<Dropzone onDrop={onDrop} multiple>
			{({ getRootProps, getInputProps }) => (
				<div>
					<div {...getRootProps({ style: containerStyle })}>
						<input name="files" {...getInputProps()} />
						{children}
					</div>
					{showPreview && (
						<aside className="previews" style={thumbsContainer}>
							{thumbs}
						</aside>
					)}
				</div>
			)}
		</Dropzone>
	);
}
