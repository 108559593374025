import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/layouts/ContentLayout";
import { Group, Select, Stack, Tabs, TextInput } from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import StoreHours from "./tabs/StoreHours";
import { getRestaurantById } from "../../api/endpoints/restaurantApi";
import AppLoader from "../../components/AppLoader";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";
import StoreInformation from "./tabs/StoreInformation";

function Stores() {
	const [loading, setLoading] = useState(true);
	const [restaurant, setRestaurant] = useState<any>(null);
	const { user } = useAppSelector(selectAuth);

	useEffect(() => {
		const fetchStore = async () => {
			const res = await getRestaurantById(user.store_id);
			console.log("restaurant data", res);

			if (res.success) {
				// handle success
				setRestaurant(res.data);
			} else {
				// handle error
				console.log("error", res);
			}

			setLoading(false);
		};

		if (!restaurant) {
			fetchStore();
		}
	}, [user.store_id]);

	if (loading) {
		return <AppLoader />;
	}

	return (
		<ContentLayout heading="Store Management">
			<Tabs defaultValue="store-info">
				<Tabs.List>
					<Tabs.Tab value="store-info">Store Information</Tabs.Tab>
					<Tabs.Tab value="hours">Business Hours</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="store-info" p="35px 20px">
					<StoreInformation data={restaurant} />
				</Tabs.Panel>
				<Tabs.Panel value="hours" p="35px 20px">
					<StoreHours />
				</Tabs.Panel>
			</Tabs>
			{/* <DataPanel title="Store Information">
				<Stack>
					<TextInput label="Store name" />
					<TextInput label="Address 1" />
					<TextInput label="Address 2" />
					<Group>
						<TextInput label="City" />
						<TextInput label="State" />
						<TextInput label="Zip" />
					</Group>
					<Select label="Type of cuisine"></Select>
				</Stack>
			</DataPanel> */}
		</ContentLayout>
	);
}

export default Stores;
