import React, { useState } from "react";
import { Stack } from "rsuite";
import CreateNew from "../CreateNew";

import { Button, FileButton, Group, Text } from "@mantine/core";
import { MdOutlineFileUpload } from "react-icons/md";

const ActionButtons = (props: any) => {
	const [file, setFile] = useState<File | null>(null);

	return (
		<Stack justifyContent="space-between">
			<h5 className="heading">Menu Manager</h5>
			<Stack spacing={8}>
				<Group>
					<FileButton
						// resetRef={resetRef}
						onChange={setFile}
						accept="application/pdf"
					>
						{(props) => (
							<Button
								style={{ color: "#1B1212", backgroundColor: "#f4f4f4" }}
								rightSection={<MdOutlineFileUpload size={17} />}
								{...props}
							>
								Import Menu
							</Button>
						)}
					</FileButton>
				</Group>
				<CreateNew categories={props.categories} />

				{file && (
					<Text size="sm" ta="center" mt="sm">
						Picked file: {file.name}
					</Text>
				)}
			</Stack>
		</Stack>
	);
};

export default ActionButtons;
