import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectAuth } from "../features/authSlice";

function AuthRedirect() {
	const { isAuthenticated, user } = useAppSelector(selectAuth);
	const location = useLocation();

	return user && isAuthenticated ? (
		<Navigate to="/dashboard" state={{ from: location }} replace />
	) : (
		<Outlet />
	);
}

export default AuthRedirect;
