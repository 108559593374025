import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { selectAuth } from "../../../features/authSlice";
import BusinessForm from "../../../components/forms/BusinessForm";

function Signup() {
	const { user, isAuthenticated } = useAppSelector(selectAuth);
	const navigate = useNavigate();
	let location = useLocation();
	let from = location.state?.from?.pathname || "/dashboard";

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [isAuthenticated]);

	return (
		<div className="signup__page">
			<div className="content">
				<h2 className="heading">Create Account</h2>

				<BusinessForm />
			</div>
			<div className="showcase"></div>
		</div>
	);
}

export default Signup;
