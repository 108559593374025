import { jwtDecode, JwtPayload } from "jwt-decode";

export function checkTokenExpiration(token: string) {
	const decoded: any = jwtDecode<JwtPayload>(token);
	const isExpired = Date.now() >= decoded.exp * 1000;

	return isExpired;
}

export const saveToStorage = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

export const getStorageItem = (key: string) => {
	return localStorage.getItem(key);
};

export const saveAccessToken = (token: string) => {
	localStorage.setItem("access-token", token);
};

export const getAccessToken = () => {
	return localStorage.getItem("access-token");
};

export const getRefreshToken = () => {
	return localStorage.getItem("refresh-token");
};

export const clearStorage = () => {
	localStorage.clear();
};
