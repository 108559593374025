import { apiInstance } from "..";

type Item = {
	name: string;
	category: string;
	description: string;
};

export const createNewItem = async (payload: any) => {
	try {
		console.log("creating new item");
		const { data } = await apiInstance.post("/dishes", payload);
		console.log("created");
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response;
	}
};

export const getAllItems = async () => {
	try {
		// handle request
		const { data } = await apiInstance.get("/dishes");
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response.data;
	}
};

export const getOneItemById = async () => {
	try {
		// handle request
	} catch (err) {
		console.log(err);
	}
};

export const updateItem = async (id: number, payload: Item) => {
	try {
		// handle request
		const { data } = await apiInstance.patch(`/dishes/${id}`, payload);
		return data;
	} catch (err) {
		console.log(err);
	}
};

export const getDishes = async (id: number) => {
	try {
		// handle request
		const { data } = await apiInstance.get(`/dishes/${id}`);
		console.log("dishes data", data);
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response.data;
	}
};

export const deleteItem = async (id: number) => {
	try {
		const { data } = await apiInstance.delete(`/dishes/${id}`);
		return data;
	} catch (err: any) {
		console.log("Error", err);
		return err.response.data;
	}
};

export const createNewCategory = async (payload: any) => {
	try {
		const { data } = await apiInstance.post("/dishes/categories", payload);
		console.log("response", data);

		return data;
	} catch (err: any) {
		console.log("Error", err);
		return err.response.data;
	}
};
