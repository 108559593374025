import React from "react";
import { Flex, TextInput } from "@mantine/core";

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

function HorizontalInputGroup({ fields, form }: any) {
	return (
		<Flex gap="xs" justify="space-between">
			{fields.map((item: any, i: number) => {
				return (
					<TextInput
						key={i.toString()}
						styles={styles}
						label={item.label}
						name={item.name}
						placeholder={item.placeholder}
						{...form.getInputProps(item.name)}
					/>
				);
			})}
		</Flex>
	);
}

export default HorizontalInputGroup;
