import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectAuth } from "../features/authSlice";

const RequireAuth = () => {
	const { isAuthenticated } = useAppSelector(selectAuth);
	const location = useLocation();

	return isAuthenticated ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireAuth;
