import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Group, Text, UnstyledButton } from "@mantine/core";
import LOGO from "../assets/logo_home.png";

interface NavigationItem {
	label: string;
	path: string;
}

const NAVIGATION: NavigationItem[] = [
	{ label: "Home", path: "/" },
	{ label: "About", path: "/about" },
	{ label: "Products", path: "/products" },
	{ label: "Pricing", path: "/pricing" },
	{ label: "Contact", path: "/contact" },
];

const linkStyles = {
	textDecoration: "none",
	color: "inherit",
};

function Header() {
	return (
		<div className="page-header">
			<div className="logo-container">
				<img src={LOGO} alt="logo" />
				<Text size="lg">for Business</Text>
			</div>

			<ul className="navigation">
				{NAVIGATION.map((item) => (
					<li>
						<Text>
							<NavLink
								to={item.path}
								className={({ isActive }) =>
									isActive ? "nav-item active" : "nav-item"
								}
							>
								{item.label}
							</NavLink>
						</Text>
					</li>
				))}
			</ul>

			<Group>
				<Link to="become-a-partner" style={linkStyles}>
					<UnstyledButton>Become a partner</UnstyledButton>
				</Link>
				<Link style={linkStyles} to="login">
					<Button color="#e4005be6">LOGIN</Button>
				</Link>
			</Group>
		</div>
	);
}

export default Header;
