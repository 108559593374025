import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { verifyAuth } from "../api/endpoints/authApi";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectAuth, initiateFromToken, logout } from "../features/authSlice";
import AppLoader from "../components/AppLoader";
import { getAccessToken, checkTokenExpiration } from "../utils/tokenUtils";

function PersistLogin() {
	const { isAuthenticated } = useAppSelector(selectAuth);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [persist, setPersist] = useState(
		localStorage.getItem("persist") || false
	);

	useEffect(() => {
		const token = getAccessToken();

		if (token) {
			const isExpired = checkTokenExpiration(token);

			if (isExpired) {
				const authenticateUser = async () => {
					const res = await verifyAuth();
					console.log(res);

					if (res?.success) {
						dispatch(initiateFromToken());
						setIsLoading(false);
					} else {
						dispatch(logout());
						navigate("/login", { replace: true });
					}
				};

				authenticateUser();
				setIsLoading(false);
			} else {
				dispatch(initiateFromToken());
				setIsLoading(false);
			}
		} else {
			dispatch(logout());
			setIsLoading(false);
		}
	}, [isAuthenticated]);

	return isLoading ? (
		<div className="app_init-loader">
			<AppLoader />
		</div>
	) : (
		<Outlet />
	);
}

export default PersistLogin;
