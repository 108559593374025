import React, { useEffect, useState } from "react";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { theme } from "./themes";

import RequireAuth from "./routes/RequireAuth";
import Login from "./pages/auth/login/Login";
import Signup from "./pages/auth/signup";
import Dashboard from "./pages/dashboard/Dashboard";
import AppLayout from "./components/layouts/AppLayout";
import NotFound from "./pages/NotFound";
import PersistLogin from "./routes/PersistLogin";
import { useAppDispatch, useAppSelector } from "./hooks";
import AuthRedirect from "./routes/AuthRedirect";
import MenuManager from "./pages/menuManager";
import ManageUsers from "./pages/manageUsers";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Stores from "./pages/stores";
import DishEdit from "./pages/dishEdit";
import Notifications from "./pages/notifications/Notifications";
import AccountSecurity from "./pages/accountSecurity";
import Account from "./pages/account/Account";
import NotificationSettings from "./pages/notificationSettings";
import { Container, CustomProvider } from "rsuite";
import { getAccessToken } from "./utils/tokenUtils";
import { verifyAuth } from "./api/endpoints/authApi";
import { initiateFromToken, logout, selectAuth } from "./features/authSlice";
import Home from "./pages/home";
import PublicLayout from "./components/layouts/PublicLayout";
import Contact from "./pages/contact";
import Pricing from "./pages/pricing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastContainerStyle = {
	width: 400,
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" errorElement={<NotFound />}>
			{/* Public routes */}

			<Route element={<PublicLayout />}>
				<Route index element={<Home />} />
				<Route path="pricing" element={<Pricing />} />
				<Route path="contact" element={<Contact />} />
			</Route>

			{/* Auth routes */}
			<Route element={<AuthRedirect />}>
				<Route path="login" element={<Login />} />
				<Route path="become-a-partner" element={<Signup />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
			</Route>

			{/* Protected routes */}
			<Route element={<PersistLogin />}>
				<Route element={<RequireAuth />}>
					{/* <Route path='onboarding' element={<Onboarding} */}
					<Route
						element={<AppLayout />}
						// loader={() => protectedLoader(isAuthenticated)}
					>
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="menu-manager" element={<MenuManager />} />
						<Route path="dish/:id" element={<DishEdit />} />
						<Route path="store" element={<Stores />} />
						<Route path="users" element={<ManageUsers />} />
						<Route
							path="view-notifications"
							element={<Notifications />}
						/>

						<Route path="account" element={<Account />} />
						<Route
							path="settings/notifications"
							element={<NotificationSettings />}
						/>
						<Route
							path="settings/account-security"
							element={<AccountSecurity />}
						/>

						{/* <Route path="/add-new-dish" element={<AddPicure />} />
						<Route path="stores" element={<Stores />} />
						<Route path="/account" element={<Account />} /> */}
					</Route>
				</Route>
			</Route>
		</Route>
	)
);

function App() {
	const dispatch = useAppDispatch();
	const { isAuthenticated } = useAppSelector(selectAuth);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const token = getAccessToken();

		if (token) {
			const authenticateUser = async () => {
				const res = await verifyAuth();

				if (res?.success) {
					dispatch(initiateFromToken());
					setLoading(false);
				} else {
					dispatch(logout());
					setLoading(false);
				}
			};

			authenticateUser();
		}

		setLoading(false);
	}, [isAuthenticated]);

	if (loading) {
		return null;
	}

	return (
		<CustomProvider theme="light">
			<Container className="app">
				<ToastContainer
					style={toastContainerStyle}
					autoClose={4000}
					position="bottom-right"
				/>
				<MantineProvider theme={theme}>
					<RouterProvider router={router} />
				</MantineProvider>
			</Container>
		</CustomProvider>
	);
}

export default App;
