import React, { useState } from "react";
import { useAppSelector } from "../../../hooks";
import { selectMenu } from "../../../features/menuSlice";
import { Group, TextInput } from "@mantine/core";
import { IoAddCircle } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { Category } from "..";

type Props = {
	data: Category[] | [];
	active: number;
	onClick: (val: number) => void;
	onSave: (val: string) => void;
};

function Categories({ data, active, onClick, onSave }: Props) {
	const { categories } = useAppSelector(selectMenu);
	const [newCategory, setNewCategory] = useState("");
	const [isAddingCategory, setIsAddingCategory] = useState(false);
	console.log("categories", categories);

	const onCancel = () => {
		setNewCategory("");
		setIsAddingCategory(false);
	};

	const handleSave = () => {
		if (newCategory === "") {
			return;
		}

		onSave(newCategory);
		setNewCategory("");
		setIsAddingCategory(false);
	};

	return (
		<div className="categories-container">
			<h4>Categories</h4>

			<div className="actions-container">
				<Group>
					{data.map((item, i) => {
						const isActive = i === active;
						const activeClassname = isActive ? " active" : "";

						return (
							<button
								key={i}
								className={`category-item${activeClassname}`}
								onClick={() => onClick(i)}
							>
								{item.label}
							</button>
						);
					})}
					{isAddingCategory && (
						<>
							<TextInput
								value={newCategory}
								name="category"
								placeholder="e.g. Lunch"
								onChange={(event) =>
									setNewCategory(event.currentTarget.value)
								}
							/>
							<div className="new-category-buttons">
								<button onClick={handleSave}>
									<FaCheck size={20} color="#979797" />
								</button>
								<button onClick={onCancel}>
									<MdDeleteForever size={20} color="#E4005B" />
								</button>
							</div>
						</>
					)}
				</Group>

				{!isAddingCategory && (
					<button
						type="button"
						className="add-item-button"
						onClick={() => setIsAddingCategory(!isAddingCategory)}
					>
						<IoAddCircle size={30} color="#E4005B" />
					</button>
				)}

				{/* <NewCategoryDrawer /> */}
			</div>
		</div>
	);
}

export default Categories;
