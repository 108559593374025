import React, { useState } from "react";
import AppButton from "../buttons/AppButton";
import { PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm, isEmail, isNotEmpty } from "@mantine/form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { registerBusiness } from "../../api/endpoints/authApi";
import { checkPasswordMatch } from "../../utils";
import { useAppDispatch } from "../../hooks";
import { setCredentials } from "../../features/authSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import StoreInputGroup from "./StoreInputGroup";
import HorizontalInputGroup from "./HorizontalInputGroup";

interface Business {
	store_name: string;
	address_1: string;
	address_2: string;
	city: string;
	state: string;
	zip: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	email: string;
	cuisine_type: string;
	num_of_locations: string;
	password: string;
	confirmPassword: string;
}

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

const MyInput = React.forwardRef((props, ref: any) => (
	<TextInput {...props} ref={ref} />
));

const HORIZONTAL_FIELDS = [
	{ label: "First Name", name: "first_name", placeholder: "" },
	{ label: "Last Name", name: "last_name", placeholder: "" },
];

const FIELDS = [
	{ label: "Email", name: "email", placeholder: "" },
	{ label: "Phone number", name: "phone_number", placeholder: "" },
	{ label: "Password", name: "password", placeholder: "" },
	{ label: "Confirm Password", name: "confirmPassword", placeholder: "" },
];

function BusinessForm() {
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let location = useLocation();

	let from = location.state?.from?.pathname || "/dashboard";

	const [state, setState] = useState<Business>({
		store_name: "",
		address_1: "",
		address_2: "",
		city: "",
		state: "",
		zip: "",
		first_name: "",
		last_name: "",
		phone_number: "",
		email: "",
		cuisine_type: "",
		num_of_locations: "",
		password: "",
		confirmPassword: "",
	});

	const form = useForm({
		initialValues: state,
		validate: {
			store_name: isNotEmpty("Store name is required"),
			address_1: isNotEmpty("Store address is required"),
			city: isNotEmpty("City is required"),
			state: isNotEmpty("State is required"),
			zip: isNotEmpty("Zip is required"),
			first_name: isNotEmpty("First name required"),
			last_name: isNotEmpty("Last name required"),
			phone_number: isNotEmpty("Phone number is required"),
			email: isEmail("Please enter a valid email address"),
			// Password validation ideas
			// Include uppercase and lowercase letter, include number,
			// include a special character: #.-?!@$%^&*
			password: isNotEmpty("You must enter a password"),
			confirmPassword: (value, values) =>
				checkPasswordMatch(values.password, value),
		},
	});

	const handleSubmit = async (values: Business) => {
		setLoading(true);

		const res = await registerBusiness(values);
		console.log("business form submission res", res);

		if (res.success) {
			// success
			dispatch(setCredentials(res.data));
			navigate(from, { replace: true });
		} else {
			// error
			form.setErrors({ email: res.data.message });
		}

		setLoading(false);
	};

	return (
		<form
			onSubmit={form.onSubmit(async (values) => handleSubmit(values))}
			className="business-form"
		>
			<div className="input_field-container">
				{/* <Flex gap="xs" justify="center" align="center"> */}

				<StoreInputGroup form={form} />

				<HorizontalInputGroup fields={HORIZONTAL_FIELDS} form={form} />

				{FIELDS.map((item, i) => {
					const isPasswordField =
						item.name === "password" || item.name === "confirmPassword";

					if (item.name === "phone_number") {
						return (
							<div key={i.toString()} className="phone-input-group">
								<span className="input-label">{item.label}</span>
								<PhoneInput
									limitMaxLength={true}
									defaultCountry="US"
									inputComponent={MyInput}
									style={styles}
									// label={item.label}
									placeholder={item.placeholder}
									{...form.getInputProps(item.name)}
								/>
							</div>
						);
					}

					return isPasswordField ? (
						<PasswordInput
							key={i.toString()}
							styles={styles}
							label={item.label}
							name={item.name}
							placeholder={item.placeholder}
							{...form.getInputProps(item.name)}
						/>
					) : (
						<TextInput
							key={i.toString()}
							styles={styles}
							label={item.label}
							name={item.name}
							placeholder={item.placeholder}
							{...form.getInputProps(item.name)}
						/>
					);
				})}
			</div>

			<div className="terms_of_agreement_wrapper">
				<Text span className="terms_of_agreement_text">
					By clicking "Continue", you agree to our <Link to="">terms</Link>{" "}
					and acknowledge our <Link to="">Privacy Policy</Link>
				</Text>
			</div>

			<AppButton
				variant="filled"
				loading={loading}
				mt="md"
				fullWidth
				type="submit"
				label="Continue"
				rightSection={<FaArrowRightLong />}
				styles={{
					root: {
						textTransform: "uppercase",
						borderRadius: 20,
						height: 48,
					},
				}}
			/>

			<Text className="login_link_text">
				Already have an account? <Link to="/login">Login</Link>
			</Text>
		</form>
	);
}

export default BusinessForm;
