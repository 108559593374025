import { apiInstance } from "..";

export const getRestaurantById = async (id: number) => {
	try {
		const { data } = await apiInstance.get(`/stores/${id}`);
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response.data;
	}
};
