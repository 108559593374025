import React, { useState, useEffect } from "react";
import { Drawer, ButtonToolbar, Button } from "rsuite";
import AppButton from "../../components/buttons/AppButton";
import { Group, Text, Stack, TextInput, Textarea } from "@mantine/core";
import { FaCloudUploadAlt } from "react-icons/fa";
import { createNewItem } from "../../api/endpoints/dishesApi";
import FileDropzone from "../../components/FileDropzone";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";
import useNotification from "../../hooks/useNotification";
import { selectMenu, updateMenu } from "../../features/menuSlice";
import { uploadFile } from "../../api/endpoints/uploadsApi";
import { Select } from "@mantine/core";

type BackdropState = boolean | "static" | undefined;

interface State {
	merchant_id: number;
	name: string;
	description: string;
}

type Props = { categories: any[] };

function CreateNew({ categories }: Props) {
	const { user } = useAppSelector(selectAuth);
	const { show } = useNotification();
	const [open, setOpen] = useState(false);
	const [category, setCategory] = useState<any>(null);
	const [backdrop, setBackdrop] = useState<BackdropState>(true);
	const [files, setFiles] = useState<any>([]);
	const [isCreating, setIsCreating] = useState(false);
	const { dishes } = useAppSelector(selectMenu);
	const dispatch = useAppDispatch();

	console.log("test", category);

	const [item, setItem] = useState<State>({
		merchant_id: user.store_id,
		name: "",
		description: "",
	});

	// useEffect(() => {
	// 	setItem({ ...item, merchant_id: user.store_id });
	// }, []);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	};

	const resetForm = () => {
		if (files.length) {
			setFiles([]);
		}

		setItem({
			merchant_id: user.store_id,
			name: "",
			description: "",
		});
	};

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () =>
			files.forEach((file: any) => URL.revokeObjectURL(file.preview));
	}, [files]);

	const handleClose = () => {
		setOpen(false);
		resetForm();
	};

	const handleCreate = async () => {
		setIsCreating(true);

		const metadata = [];
		let categorySubDir = category ? `/${category}/` : "/";

		// handle dish picture uploads
		for (let photo of files) {
			// formData.append("file", photo, photo.name);
			const filepath = `${item.merchant_id}${categorySubDir}${photo.name}`;
			const uploadResponse: any = await uploadFile("menus", filepath, photo);
			console.log("upload response", uploadResponse);
			const { id, fullPath, path } = uploadResponse;
			const public_url = `${process.env.REACT_APP_SUPABASE_STORAGE_URL}/${fullPath}`;

			metadata.push({
				bucket_id: "menus",
				user_id: user.id,
				filename: photo.name,
				id: id,
				fullPath,
				path,
				s3_location: filepath,
				doctype: photo.type,
				filesize: photo.size,
				public_url,
			});
		}

		const payload = { ...item, category, uploads: metadata };
		const res = await createNewItem(payload);
		console.log("New Item created response", res);

		if (res.success) {
			// handle success
			resetForm();

			const newDish = {
				id: res.data.id,
				name: res.data.name,
				description: res.data.description,
				serving_amount: res.data.serving_amount,
				isVisible: res.data.availability,
				category: res.data.category,
			};

			const arr = [...dishes, newDish];

			setOpen(false);
			dispatch(updateMenu({ type: "dishes", data: arr }));

			show("Success", res.message, "success");
			setIsCreating(false);
		} else {
			// handle error
			show("Error", res.message, "error");
			setIsCreating(false);
		}

		setIsCreating(false);
	};

	return (
		<>
			<ButtonToolbar>
				<AppButton onClick={() => setOpen(true)} label="Create New" />
			</ButtonToolbar>
			<Drawer
				size="28rem"
				backdrop={backdrop}
				open={open}
				onClose={handleClose}
			>
				<Drawer.Header>
					<Drawer.Title>New Dish</Drawer.Title>
					<Drawer.Actions>
						<Group>
							<Button onClick={handleClose}>Cancel</Button>
							<AppButton
								label="Create"
								onClick={handleCreate}
								disabled={isCreating}
							/>
						</Group>
					</Drawer.Actions>
				</Drawer.Header>
				<Drawer.Body>
					<form>
						<Stack>
							<TextInput
								label="Dish Name"
								placeholder="What's your new dish called?"
								name="name"
								value={item.name}
								onChange={handleChange}
							/>
							<Select
								label="Category"
								value={category}
								onChange={setCategory}
								comboboxProps={{ withinPortal: false }}
								data={categories.map((item) => ({
									value: String(item.value),
									label: item.label,
								}))}
								clearable
							/>
							<Textarea
								minRows={5}
								rows={5}
								autosize
								name="description"
								label="Description"
								value={item.description}
								onChange={handleChange}
								placeholder="Write a description for your dish on its ingredients"
							/>

							<FileDropzone
								files={files}
								showPreview={true}
								setFiles={setFiles}
							>
								<div
									style={{
										height: 150,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<FaCloudUploadAlt size={38} />
									<Text size="sm" c="dimmed" mt={6}>
										Click or Drag a picture of your new dish
									</Text>
								</div>
							</FileDropzone>
						</Stack>
					</form>
				</Drawer.Body>
			</Drawer>
		</>
	);
}

export default CreateNew;
