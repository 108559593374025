import React from "react";
import { toast } from "react-toastify";

type ToastType =
	| "info"
	| "success"
	| "warning"
	| "error"
	| "default"
	| undefined;

const AppAlert = ({ title, text }: { title: string; text: string }) => {
	return (
		<div className="msg-container">
			<p className="msg-title">{title}</p>
			<p className="msg-description">{text}</p>
		</div>
	);
};

function useNotification() {
	function notify(title: string, text: string, type: ToastType) {
		const data = { title, text };
		const toastType = { type };

		toast(<AppAlert {...data} />, toastType);
	}

	const values = {
		show: notify,
	};

	return values;
}

export default useNotification;
