import React from "react";
import Copyright from "./Copyright";

function Footer() {
	return (
		<div className="page-footer">
			<Copyright color="#fff" />
		</div>
	);
}

export default Footer;
