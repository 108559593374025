import React from "react";
import { TextInput } from "@mantine/core";
import HorizontalInputGroup from "./HorizontalInputGroup";

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

const HORIZONTAL_FIELDS = [
	{ label: "City", name: "city", placeholder: "" },
	{ label: "State", name: "state", placeholder: "" },
	{ label: "Zip", name: "zip", placeholder: "" },
];

const FIELDS = [
	[
		{ label: "Store name", name: "store_name", placeholder: "" },
		{
			label: "Address",
			name: "address_1",
			placeholder: "Enter store address",
		},
		{
			label: "Apt, Suite, etc. (Optional)",
			name: "address_2",
			placeholder: "",
		},
	],
	[
		{ label: "Cuisine Type", name: "cuisine_type", placeholder: "" },
		{
			label: "Number of locations",
			name: "num_of_locations",
			placeholder: "",
		},
	],
];

function StoreInputGroup({ form }: any) {
	return (
		<>
			{FIELDS[0].map((item: any, i: number) => {
				return (
					<TextInput
						key={i.toString()}
						styles={styles}
						label={item.label}
						name={item.name}
						placeholder={item.placeholder}
						{...form.getInputProps(item.name)}
					/>
				);
			})}

			<HorizontalInputGroup fields={HORIZONTAL_FIELDS} form={form} />

			{FIELDS[1].map((item: any, i: number) => {
				return (
					<TextInput
						key={i.toString()}
						styles={styles}
						label={item.label}
						name={item.name}
						placeholder={item.placeholder}
						{...form.getInputProps(item.name)}
					/>
				);
			})}
		</>
	);
}

export default StoreInputGroup;
